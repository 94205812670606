import React from "react"
import { Pagination, Button, message } from "antd"
import { navigate } from "gatsby"

import { scrollToTop, queryParse } from "../../utils/helper"
import Layout from "../../layouts"
import ThemeCard from "../../components/ThemeCard"
import { MEDIA_INTRO } from "../../utils/constant"

import "./index.css"
import { getJobList } from "../../api/job"

const pageSize = 12
const template = new Array(pageSize).fill({}).map((_, index) => ({
  id: index,
  title: "",
  url: "",
  coverUrl: "",
  qrCode: "",
}))

class JobList extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      jobList: template,
      currentPage: 0,
      total: 0,
      loading: true,
    }
  }

  componentWillMount() {
    this.initJobList()
  }

  initJobList = async () => {
    const param = queryParse()
    let { currentPage } = this.state
    if (param.page !== undefined) {
      currentPage = param.page
    }
    this.setState({ currentPage, jobList: template, loading: true })
    const res = await getJobList(currentPage, pageSize)
    const data = res.data.data
    if (data) {
      const { list, pageNum } = data
      this.setState({
        jobList: list,
        loading: false,
        total: pageNum,
        currentPage,
      })
    } else {
      message.error("加载岗位界面出错")
    }
  }

  handlePagination = async page => {
    scrollToTop()
    const url = `/jobs?page=${page}`
    if (typeof window !== "undefined") {
      window.history.pushState({ url: url }, "", url)
    }
    this.initJobList()
  }

  handleAddNewTheme = () => {
    navigate("/jobs/upload")
  }

  render() {
    const { total, currentPage, jobList } = this.state
    return (
      <Layout isSpacerDisplay={true} title="全部职位">
        <div className="mdnice-theme-container">
          <div className="mdnice-theme-list">
            {jobList.map((item, index) => (
              <ThemeCard key={index} {...item} />
            ))}
            {/* <div style={{ width: 330, margin: "0em 1em" }} />
            <div style={{ width: 330, margin: "0em 1em" }} /> */}
          </div>
          <div className="mdnice-theme-footer">
            <Pagination
              className="pagination"
              defaultPageSize={pageSize}
              current={currentPage ? parseInt(currentPage) : 1}
              total={total}
              onChange={this.handlePagination}
            />
          </div>
        </div>
      </Layout>
    )
  }
}

export default JobList
